import { render, staticRenderFns } from "./FormWelcome.vue?vue&type=template&id=0d263a5d&scoped=true"
import script from "./FormWelcome.vue?vue&type=script&lang=js"
export * from "./FormWelcome.vue?vue&type=script&lang=js"
import style0 from "./FormWelcome.vue?vue&type=style&index=0&id=0d263a5d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d263a5d",
  null
  
)

export default component.exports